<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <template #header>
        <h4>Genel Bilgiler</h4>
        <b-form-checkbox
          v-model="user.detailForm"
          name="check-button"
          switch
          inline
        >
          Detaylı
        </b-form-checkbox>
      </template>
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <name-surname />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <email />
        </b-col>
      </b-row>
      <b-row v-if="user.detailForm">
        <b-col
          sm="12"
          md="6"
        >
          <birthday />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <start-date />
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <tckn />
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <phone />
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <internal />
        </b-col>
        <b-col
          sm="12"
          md="12"
        >
          <password />
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Marka & Departman Bilgileri">
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <brand />
        </b-col>
        <b-col
          sm="12"
          :md="user.id_com_department === '7' ? 3:6"
        >
          <department />
        </b-col>
        <b-col
          v-if="user.id_com_department === '7'"
          sm="12"
          md="3"
        >
          <insurance-id-card />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <manager />
        </b-col>
        <b-col
          sm="user_sect"
          md="3"
        >
          <user-section />
        </b-col>
        <b-col
          sm="12"
          md="3"
        >
          <location />
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Kullanıcı Tipi & Diğer Bilgiler">
      <b-row>
        <b-col
          sm="12"
          md="3"
        >
          <user-type />
        </b-col>
        <b-col
          sm="12"
          md="3"
        >
          <webform />
        </b-col>
        <b-col
          sm="12"
          md="3"
        >
          <cronjob />
        </b-col>
      </b-row>
    </b-card>
    <save-button :action-methods="submitForm" />
  </validation-observer>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import NameSurname from '@/views/Core/Users/forms/NameSurname'
// eslint-disable-next-line import/extensions
import Email from '@/views/Core/Users/forms/Email'
// eslint-disable-next-line import/extensions
import Brand from '@/views/Core/Users/forms/Brand'
// eslint-disable-next-line import/extensions
import Department from '@/views/Core/Users/forms/Department'
// eslint-disable-next-line import/extensions
import Manager from '@/views/Core/Users/forms/Manager'
// eslint-disable-next-line import/extensions
import UserType from '@/views/Core/Users/forms/UserType'
// eslint-disable-next-line import/extensions
import Location from '@/views/Core/Users/forms/Location'
// eslint-disable-next-line import/extensions
import Webform from '@/views/Core/Users/forms/Webform'
// eslint-disable-next-line import/extensions
import Cronjob from '@/views/Core/Users/forms/Cronjob'
// eslint-disable-next-line import/extensions
import Birthday from '@/views/Core/Users/forms/Birthday'
// eslint-disable-next-line import/extensions
import StartDate from '@/views/Core/Users/forms/StartDate'
// eslint-disable-next-line import/extensions
import Tckn from '@/views/Core/Users/forms/Tckn'
// eslint-disable-next-line import/extensions
import Phone from '@/views/Core/Users/forms/Phone'
// eslint-disable-next-line import/extensions
import Internal from '@/views/Core/Users/forms/Internal'
// eslint-disable-next-line import/extensions
import Password from '@/views/Core/Users/forms/Password'
// eslint-disable-next-line import/extensions
import SaveButton from '@/views/Core/Users/forms/SaveButton'
import InsuranceIdCard from '@/views/Core/Users/forms/insuranceId.vue'
import UserSection from '@/views/Core/Users/forms/Section.vue'

export default {
  name: 'Add',
  components: {
    UserSection,
    InsuranceIdCard,
    ValidationObserver,
    NameSurname,
    Email,
    Brand,
    Department,
    Manager,
    UserType,
    Location,
    Webform,
    Cronjob,
    Birthday,
    StartDate,
    Tckn,
    Phone,
    Internal,
    Password,
    SaveButton,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
    saveStatus() {
      return this.$store.getters['users/getUserSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.user.submitStatus = false
    },
  },
  created() {
    this.getUser()
    localize(this.locale)
  },
  methods: {
    getUser() {
      this.$store.dispatch('users/userView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.user.submitStatus = true
          this.user.editUser = true
          this.$store.dispatch('users/userSave', this.user)
        }
      })
    },
  },
}
</script>
